.social-footer {
  position: fixed;
  background-color: white;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -2px 4px -1px rgb(0 0 0 / 20%),
    0px -4px 5px 0px rgb(0 0 0 / 14%), 0px -1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 1100;
  padding: 16px;
  gap: 24px;
  flex-wrap: wrap;
}

@media screen and (min-width: 1100px) {
  .social-footer {
    justify-content: space-around;
  }
}

.social-footer-link {
  display: flex;
  align-items: center;
  color: inherit;
}

.social-footer-link-icon {
  margin-right: 4px;
}

.address-block {
  display: flex;
  align-items: center;
}
