.cakes-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.cakes-container-spinner {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
}