header {
  background-color: white !important;
  padding-top: 12px;
  padding-bottom: 12px;
}

.app-bar-toolbar {
  padding: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.app-bar-links-div {
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
}

.app-bar-logo {
  width: 80%;
}

@media (min-width: 770px) {
  .app-bar-logo {
    width: 60%;
  }
}

@media (min-width: 1400px) {
  .app-bar-logo {
    width: 50%;
  }
}

@media (min-width: 1800px) {
  .app-bar-logo {
    width: 40%;
  }
}

.MuiToolbar-root {
  min-height: 24px !important;
}