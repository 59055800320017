.cakes-container > .MuiCard-root {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 24px;
  width: 360px;
  height: 630px;
  position: relative;
}


.cake-card-title {
  line-height: 1.334em;
  height: 2.668em;
  overflow: hidden;
}