.page {
    position: relative;
    min-height: 100vh;
}

.page-content {
    padding-bottom: 6.5rem;
}
@media screen and (min-width: 900px) {
    .page-content {
        padding-bottom: 4.5rem;
    }
}